body {
  margin: 0;
  background-color: black;
  color: white;
  font-family: "Roboto", sans-serif;
  font-size: 1.25rem;
  padding-bottom: 1.5rem;
}
p {
  margin: 0;
}
input {
  width: 100%;
  padding: 1rem;
  border-radius: 2.5rem;
  font-size: 1.8rem;
  text-align: center;
  outline: none;
}
button {
  text-transform: uppercase;
  padding: 1rem;
  border-radius: 2.5rem;
  font-size: 1.8rem;
  background-color: #4db6ac;
  border: none;
  outline: none;
}
button:hover,
button:focus {
  box-shadow: 0px 6px 9px 1px #b2dfdb;
}
a {
  color: white;
}
#game-jumbotron {
  background-image: url("../images/games_bg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  max-height: 400px;
}
.align-center {
  align-items: center;
}
.align-end {
  align-items: flex-end;
}
.align-base {
  align-items: baseline;
}
.bg-black {
  background-color: black;
}
.bg-gray {
  background-color: #212121;
}
.g-1 {
  gap: 0.5rem;
}
.g-2 {
  gap: 1rem;
}
.gradient-1 {
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.9) 40%, rgba(0, 0, 0, 1) 100%);
}
.grid {
  display: grid;
}
.flex {
  display: flex;
}
.h-24 {
  height: 24rem;
}
.justify-between {
  justify-content: space-between;
}
.justify-around {
  justify-content: space-around;
}
.max-w-md {
  max-width: 480px;
}
.min-h-full{
  min-height: 98vh;
}
.p-2 {
  padding: 0.5rem;
}
.p-8 {
  padding: 2rem;
}
.px-12 {
  padding-left: 3rem;
  padding-right: 3rem;
}
.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.pb-3 {
  padding-bottom: 1.5rem;
}
.place-center {
  text-align: center;
  place-items: center;
}
.pt-8 {
  padding-top: 4rem;
}
.-mb-1 {
  margin-bottom: -0.25rem;
}
.mb-4 {
  margin-bottom: 1rem;
}
.stretch {
  flex: 1;
}
.text-bold {
  font-weight: 900;
}
.text-center {
  text-align: center;
}
.text-sm {
  font-size: 0.8rem;
}
.text-gray {
  color: #9e9e9e;
}
.text-light {
  font-weight: 100;
}
.text-xl {
  font-size: 1.5rem;
}
.text-2xl {
  font-size: 2rem;
  letter-spacing: 0.05rem;
}
.wide {
  letter-spacing: 0.1rem;
}
.w-half {
  width: 50%;
}
.w-full {
  width: 100%;
}
.wrap {
  flex-wrap: wrap;
}
.error-message {
  color: #dc3545;
  font-weight: bold;
  margin-top: 5px;
}

@keyframes button_slide {
  0% {
    left: -10px;
  }
  5% {
    left: 10px;
  }
  10% {
    left: -5px;
  }
  15% {
    left: 5px;
  }
  20% {
    left: 0px;
  }
  100% {
    left: 0px;
  }
}

.btn_anime{
  position: relative;
  animation-name: button_slide;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-delay: 0s;
}
