// import bootstrap
// @import "node_modules/bootstrap/scss/bootstrap";

// Variables
$theme-colors: (
  "primary": #7902ff,
  "secondary": #e601fe,
  "success": #28a745,
  "warning": #ffc107,
  "danger": #dc3545,
  "light": #f8f9fa,
  "dark": #343a40,
);

$white: #ffffff;

$body-bg: #010101;

// purple
// // Variables
// $theme-colors: (
//   'primary': #8000ff,
//   // Deep Purple
//   'secondary': #7e83ff,
//   // Light Blue
//   'success': #45a745,
//   // Green (No change)
//   'warning': #ffc107,
//   // Yellow (No change)
//   'danger': #dc3545,
//   // Red (No change)
//   'light': #f8f9fa,
//   // Light Gray (No change)
//   'dark': #202034,
//   // Dark Purple
// );

// $white: #ffffff; // White (No change)

// $body-bg: #4a3d6e; // Dark Purple

// brown
// // Variables
// $theme-colors: (
//   'primary': #ff026a,
//   'secondary': #fe7101,
//   'success': #8db600,
//   'warning': #ffc107,
//   'danger': #dc3545,
//   'light': #f8f9fa,
//   'dark': #46382d,
// );

// $white: #ffffff;

// $body-bg: #673a2f;

// gray
// $theme-colors: (
//   'primary': #b602ff,
//   'secondary': #fe01c1,
//   'success': #28a745,
//   'warning': #ffc107,
//   'danger': #dc3545,
//   'light': #f8f9fa,
//   'dark': #58595b,
// );

// $white: #ffffff;

// $body-bg: #453d3f;

// red
// // Variables
// $theme-colors: (
//   'primary': #ff6f59,
//   'secondary': #ffd64d,
//   'success': #4ecdc4,
//   'warning': #ff9f1c,
//   'danger': #e71d36,
//   'light': #fdfffc,
//   'dark': #011627,
// );

// $white: #fffcf2;

// $body-bg: #d90429;

// red
// Variables
// $theme-colors: (
//   'primary': #e53e3e,
//   'secondary': #f6e05e,
//   'success': #38a169,
//   'warning': #dd6b20,
//   'danger': #b7791f,
//   'light': #edf2f7,
//   'dark': #2d3748,
// );

// $white: #f7fafc;

// $body-bg: #742a2a;

$body-color: $white;

$primary: map-get($theme-colors, "primary");
$secondary: map-get($theme-colors, "secondary");
$success: map-get($theme-colors, "success");
$info: map-get($theme-colors, "info");
$warning: map-get($theme-colors, "warning");
$danger: map-get($theme-colors, "danger");
$light: map-get($theme-colors, "light");
$dark: map-get($theme-colors, "dark");

@import "~bootstrap/scss/bootstrap.scss";
// import montserrat font
// @import url('https://fonts.googleapis.com/css?family=Montserrat:400,700&display=swap');

// Custom styling
body {
  background-color: $body-bg;
  color: $body-color;
  // font-family: 'Montserrat', sans-serif;
}

.btn-gradient {
  background: $primary;
  background: linear-gradient(90deg, $secondary 0%, $primary 100%);
  border: none;
  &:hover {
    background: linear-gradient(90deg, darken($secondary, 15%) 0%, darken($primary, 15%) 100%);
  }
  &:active {
    background: linear-gradient(90deg, darken($secondary, 20%) 0%, darken($primary, 20%) 100%);
  }
}

.game-card {
  border-radius: 10px;
  height: 150px;
  object-fit: cover;
}

.banner-img {
  width: 100%;
  max-height: 300px;
  object-fit: cover;
}

// small devices (landscape phones, 576px and up)
@media (max-width: 576px) {
  .banner-img {
    width: 100%;
    height: 200px;
  }
}

// medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .banner-img {
    width: 100%;
    height: 170px;
  }

  .container {
    max-width: 750px;
  }
}

.text-shadow {
  text-shadow: 2px 2px 4px #000000;
}
